.wrapper {
  background-color: var(--ui-colors-neutral-default);
}
.content {
  border-bottom: 1px solid var(--ui-colors-grey-light);
  padding: 32px 0;
}
.disclaimer {
  color: var(--ui-colors-grey-default);
}
