.video {
  position: relative;
  aspect-ratio: 4 / 5;
  border-radius: 16px;
  overflow: hidden;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: token(breakpoints.minMd)) {
    border-radius: 40px;
    aspect-ratio: 16 / 9;
  }
}
.customContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: token(breakpoints.minMd)) {
    gap: 64px;
  }
  @media (min-width: token(breakpoints.minLg)) {
    gap: 40px;
  }
}
